// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
//@import 'utilities/wrapper';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'modules/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



a.btn-link {
    width: 250px;
    height: 80px;
    background: $color-1;
    border-radius: 10px;
    border: 3px solid $color-1;
    transition: 0.3s;
    h5 {    
        font-family: $font-family-2;
        font-weight: normal;
        font-size: 18px;
        color: $color-2;
        text-transform: uppercase;
        letter-spacing: 3.6px;
        transition: 0.3s;
    }
    &:hover {
        background: transparent;
        border: 3px solid $color-white;
        transition: 0.3s;
        h5 {
            color: $color-white;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: 1024px) {
        h5 {
            font-size: 16px;
        }
        @media screen and (max-width: $size-xs-max) {
            width: 200px;
        }
    }
}






/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 50%;
            padding: 0 5%;
            width: 100%;
            z-index: $z-index-slide-caption;
            h2 {
                font-weight: 700;
                font-size: $font-size-46;
                color: $color-2;
                padding-bottom: 25px;
            }
            p {
                font-size: $font-size-20;
                line-height: 1.3;
                margin-bottom: 60px;
            }
            @media screen and (max-width: 1500px){
                p {
                    margin-bottom: 35px;
                }
                @media screen and (max-width: 1200px){
                    h2 {
                        font-size: 2rem;
                    }
                    p {
                        font-size: 16px;
                    }
                    a.btn-link {
                        width: 225px;
                        height: 70px;
                    }
                    @media screen and (max-width: 1024px){
                        h2 {
                            font-size: 1.8rem;
                            padding-bottom: 15px;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            top: 40%;
                            h2 {
                                padding-bottom: 10px;
                            }
                            p {
                                margin-bottom: 25px;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                top: 50%;
                                h2, p {
                                    br {
                                        display: none;
                                    }
                                } 
                                @media screen and (max-width: 375px) {
                                    top: 43%;
                                    @media screen and (max-width: 360px) {
                                        top: 43%;
                                    }
                                }
                            }  
                        } 
                    }
                }
            }
            &.realisations {
                top: 68%;
                h2 {
                    font-weight: 600;
                    padding-bottom: 15px;
                }
                p {
                    font-family: $font-family-1;
                    font-size: $font-size-26;
                }
                @media screen and (max-width: 1200px){
                    h2 {
                        font-size: 2.3rem;
                    }
                   @media screen and (max-width: 1024px){
                        h2 {
                            font-size: 2.3rem;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            top: 65%;
                            h2 {
                                font-size: 2.1rem;
                            }
                            p {
                                font-size: 1.15rem;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                top: 70%;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 90px 5% 20px 5%;
    .text-side {
        background: $color-3;
        border-radius: 9px;
        width: 50%;
        padding: 50px 40px;
        margin-right: 45px;
        h4 {
            font-weight: 500;
            font-size: $font-size-36;
            padding-bottom: 25px;
            color: $color-2;
        }
        p {
            padding-bottom: 80px;
        }
        .btn-link {
            width: 300px;
        }
    }
    .image-side {
        width: 50%;
        background: url("../images/accueil_bloc1.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1595px) {
        .text-side {
            p {
                padding-bottom: 65px;
                br {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1435px) {
            .text-side {
                h4 br {
                    display: none;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                .image-side {
                    width: 75%;
                    margin: 0 auto 45px auto;
                }
                .text-side {
                    width: 75%;
                    margin: 0 auto;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .image-side {
                            background: url("../images/accueil_bloc1_m.jpg") no-repeat;
                            background-size: cover;
                            background-position: center;
                        }
                        .text-side {
                            padding: 65px 20px;
                            .btn-link {
                                width: 220px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    padding: 75px 0 135px 0;
    .title {
        padding: 25px 0 115px 0;
        margin: 0 5%;
        border-top: 1px solid $color-1;
        h3 {
            font-weight: 600;
            font-size: $font-size-60;
            color: $color-3;
            text-align: center;
            text-transform: initial;
            font-style: italic;
        }
    }

    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            // border: 1px solid black;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            width: 100%;
            &:nth-child(1) {
                background-image: url("../images/accueil_bloc3_slide01.jpg");
            }
            &:nth-child(2) {
                background-image: url("../images/accueil_bloc3_slide02.jpg");
            }
            &:nth-child(3) {
                background-image: url("../images/accueil_bloc3_slide03.jpg");
            }

            div.txt-box {
                position: absolute;
                top: 75%;
                left: 50%;
                transform: translate(-50% , -50%);
                div.wrapper {
                    position: relative;
                }
                h3 {
                    color: $color-white;
                    font-weight: bold;
                    font-size: 180px;
                    transition: .5s;
                    text-align: center;
                    &.overlay {
                        opacity: 0;
                        visibility: hidden;
                        transition: .5s;
                        position: absolute;
                        top: 0;
                        width: 0;
                    }
                }
            }
            &.active {
                div.txt-box {
                    h3 {
                        font-size: 100px;
                        opacity: 0;
                        text-transform: initial;
                        visibility: hidden;
                        transition: .5s;
                        &.overlay {
                            transition: .5s;
                            opacity: 1;
                            width: auto;
                            transform: translate(-50% , 0);
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .section-images.accordion {
            .image.accordion__item {
                div.txt-box h3 {
                    font-size: 125px;
                }
                &.active div.txt-box h3 {
                    font-size: 60px;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            margin: 0 5%;
            padding: 75px 0;
            .title {
                margin: 0;
                padding: 35px 0 75px 0;
                h3 {
                    text-align: center;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}

#section-services {
    padding: 100px 5%;
    background: $color-2;
    .bloc {
        background: $color-1;
        border-radius: 16px;
        width: 300px;
        height: 320px;
        .icon {
            padding-bottom: 60px;
        }
        .text {
            h4 {
                font-weight: 600;
                font-size: $font-size-20;
                color: $color-white;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 1500px) {
        .bloc {
            height: 300px;
            .icon {
                padding-bottom: 50px;
            }
        }
        @media screen and (max-width: 1250px) {
            flex-wrap: wrap;
            justify-content: center;
            .bloc {
                width: 40%;
                height: 310px;
                margin: 5px;
            }
            @media screen and (max-width: $size-sm-max) {
                .bloc {
                    width: 48%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .bloc {
                        width: 100%;
                        height: 300px;
                        margin: 0 0 15px 0;
                        .icon {
                            width: 40%;
                            display: flex;
                            justify-content: center;
                        }
                        .text {
                            h4 {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        } 
    }
}

#section-partenaires {
    padding: 25px 0;
    margin: 125px 5% 150px 5%;
    border-top: 1px solid #707070;
    .title {
        width: 50%;
        margin-right: 50px;
    }
    h3 {
        font-weight: 600;
        font-size: $font-size-46;
        color: $color-3;
        text-transform: initial;
        font-style: italic;
        padding-bottom: 25px;
    }
    p {
        color: $color-3;
    }
    @media screen and (max-width: 1225px) {
        flex-direction: column;
        margin: 125px 5% 50px 5%;
        .title {
            width: 100%;
            margin: 0 0 35px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            h3 {
                text-align: center;
            }
        }
    }
}

#section-accreditations {
    padding: 25px 0;
    margin: 0 5% 150px 5%;
    border-top: 1px solid #707070;
    h3 {
        font-weight: 600;
        font-size: $font-size-46;
        color: $color-3;
        text-transform: initial;
        font-style: italic;
    }
    .section-logo {
        padding-top: 5px;
        justify-content: right;
    }
    @media screen and (max-width: 1500px) {
        .section-logo {
            padding-top: 50px;
        }
        @media screen and (max-width: $size-sm-max) {
            margin: 0 5% 100px 5%;
            .section-logo {
                .logo.mr10 {
                    margin-right: 2rem;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                h3 {
                    text-align: center;
                }
                .section-logo {
                    flex-direction: column;
                    .logo.mr10 {
                        margin: 0 0 50px 0;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-image-text-color {
    padding: 60px 5% 100px 5%;
    position: relative;
    .image-side {
        width: 50%;
        margin-right: 50px;
        background: url("../images/services_bloc01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    .text-side {
        width: 50%;
        padding: 50px;
        background: $color-2;
        border-radius: 9px;
        margin-top: 40px;
        margin-right: 40px;
        h3 {
            font-weight: 800;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 30px;
        }
        p {
            color: $color-3;
        }
    }
    .blue-square {
        position: absolute;
        background: $color-1;
        width: 35%;
        height: 70%;
        border-radius: 9px;
        right: 5%;
        z-index: -1;
    }
    @media screen and (max-width: 1550px) {
        .blue-square {
            height: 67%;
        }
        @media screen and (max-width: $size-md-max) {
            .blue-square {
                height: 60%;
                width: 33%;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                padding: 75px 5%;
                .blue-square {
                    display: none;
                }
                .image-side {
                    width: 75%;
                    margin: auto;
                }
                .text-side {
                    width: 75%;
                    margin: 35px auto 0 auto;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .text-side {
                            padding: 50px 25px;
                        }
                        .image-side {
                            background: url("../images/services_bloc01_m.jpg") no-repeat;
                            background-size: cover;
                            background-position: center;
                        }
                    }
                }
            }
        }
    }
    &.second {
        .image-side {
            background: url("../images/services_bloc05.jpg") no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url("../images/services_bloc05_m.jpg") no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }
}

#section-background-image {
    padding: 20px 5%;
    background: $color-2;
    .text-side {
        width: 47%;
        margin-right: 100px;
        h3 {
            font-weight: 800;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 40px;
        }
        p {
            padding-bottom: 60px;
            color: $color-3;
        }
        .btn-link:hover {
            border: 3px solid $color-black;
            h5 {
                color: $color-black;
            }
        }
    }

    .image-side {
        width: 53%;
        border-radius: 9px;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        padding: 75px 5%;
        .image-side {
            width: 75%;
            margin: 0 auto 50px auto;
            background: none;
            padding-left: 0;
            border-radius: 0;
        }
        .text-side {
            width: 75%;
            margin: auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side, .text-side {
                width: 100%;
            }
        }
    }
}

#section-image-text {
    padding: 100px 5% 0 5%;
    .image-side {
        width: 50%;
        background: url("../images/services_bloc03.jpg") no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 60px;
    }
    .text-side {
        width: 50%;
        background: $color-2;
        border-radius: 9px;
        padding: 50px 70px;
        h3 {
            font-weight: 800;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 30px;
        }
        p {
            color: $color-3;
        }
    }
    @media screen and (max-width: 1550px) {
        .text-side {
            padding: 50px;
            p {
                padding-bottom: 50px;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            padding: 100px 5% 0 5%;
            .image-side {
                width: 75%;
                margin: auto;
            }
            .text-side {
                width: 75%;
                margin: 35px auto 0 auto;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .text-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .image-side {
                        background: url("../images/services_bloc03_m.jpg") no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                    .text-side {
                        padding: 50px 25px;
                        h3 {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}

#section-text-image-full {
    padding: 100px 0 40px 0;
    .text-side {
        width: 47%;
        background: $color-2;
        border-radius: 9px;
        margin-right: 50px;
        padding: 50px 50px 50px 5%;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 30px;
        }
        p {
            color: $color-3;
        }
    }
    .image-side {
        width: 53%;
        background: url("../images/services_bloc04.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1500px) {
        .text-side {
            p {
                padding-bottom: 50px;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            padding: 100px 5% 0 5%;
            .image-side {
                width: 75%;
                margin: auto;
            }
            .text-side {
                width: 75%;
                margin: 35px auto 0 auto;
                padding: 50px;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .text-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .image-side {
                        background: url("../images/services_bloc04_m.jpg") no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                    .text-side {
                        padding: 50px 25px;
                        h4 {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}

#section-text {
    padding: 95px 5%;
    background: $color-1;
    margin: 0 5% 100px 5%;
    text-align: center;
    border-radius: 9px;
    h4 {
        font-weight: bold;
        font-size: $font-size-40;
        color: #EAF7FF;
        line-height: 1.3;
        text-transform: uppercase;
    }
    @media screen and (max-width: $size-md-max) {
        h4 br {
            display: none;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            margin: 0 5% 75px 5%;
            h4 {
                font-size: 1.8rem;
            }
            @media screen and (max-width: 375px) {
                h4 {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 100px 5% 25px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        a.item {
            padding-bottom: 75px;
        }
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            a.item {
                padding-bottom: 50px;
            }
            @media screen and (max-width: $size-sm-max){
                a.item {
                    padding-bottom: 0;
                }
                @media screen and (max-width: $size-xs-max){
                    grid-template-columns: repeat(1, 1fr);    
                }
            }
        }
        #blocIntro_promotions {
            h3 {
                font-weight: 800;
                font-size: $font-size-30;
                color: $color-3;
                text-transform: initial;
            }
        }
    }
    @media screen and (max-width: $size-md-max){
        padding: 100px 5% 50px 5%;
        @media screen and (max-width: $size-xs-max){
            padding: 75px 5%;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-info {
    background: $color-white;
    padding: 60px 50px;
    border-radius: 9px;
    position: relative;
    margin: -70px 5% 0 5%;
    .bloc {
        h3 {
            font-weight: 700;
            font-size: $font-size-30;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 25px;
        }
        p, a {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-20;
            color: #00000080;
        }
    }
    @media screen and (max-width: 1200px){
        margin: 0;
        padding: 75px 5% 0 5%;
        flex-direction: column;
        .bloc {
            h3 {
                padding-bottom: 15px;
            }
            &.mr8 {
                margin: 0 0 35px 0;
            }
        }
        @media screen and (max-width: $size-xs-max){
            padding: 50px 5%;
        }
    }
}

#section-form-heures {
    padding: 75px 5% 100px 5%;
    .section-form {
        width: 65%;
        margin-right: 5%;
        h3 {
            font-weight: 700;
            font-size: $font-size-30;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 35px;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-26;
            color: $color-3;
            padding-bottom: 50px;
            @media screen and (max-width: 1610px) {
                br {
                    display: none;
                }
            }
        }
    }
    .section-heures {
        width: 35%;
        h3 {
            font-weight: 700;
            font-size: $font-size-30;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 45px;
        }
        .jours-heures {
            h4, h5 {
                font-family: $font-family-3;
                font-weight: bold;
                font-size: $font-size-18;
                color: $color-black;
                line-height: 2.3;
            }
            h5 {
                font-family: $font-family-1;
                font-weight: normal;
                text-align: right;
            }
            .jour {
                width: 39%;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .section-heures {
            .border {
                width: 450px;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .section-heures {
                width: 100%;
                margin: 0 0 75px 0;
                .jours-heures .jour {
                    width: 27%;
                }
            }
            .section-form {
                width: 100%;
                margin-right: 0;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 25px 5% 75px 5%;
                .section-heures {
                    .border {
                        width: 100%;
                        margin-bottom: 35px;
                    }
                    .jours-heures .jour {
                        width: 50%;
                    }
                }
                .section-form {
                    h3, h4 {
                        text-align: center;
                    }
                    .col-sm-6.pr0, .col-sm-6.pl0 {
                        padding: 0;
                    }
                    .form-group.pt7 {
                        flex-direction: column;
                        .btn, label.filedoc {
                            width: 150px;
                            height: 75px;
                        }
                        .btn {
                            margin-bottom: 15px;
                        }
                        label.filedoc {
                            width: 175px;
                        }
                    }
                }
            }
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    .popup-box img {
        width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
    

}
