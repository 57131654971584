.btn, label.filedoc {
    background-color: $color-1;
    border: 3px solid $color-1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 80px;
    padding: 0;

    font-family: $font-family-3 !important;
    font-weight: normal;
    text-transform: uppercase;
    font-size: $font-size-18;
    letter-spacing: 3.6px;
    color: $color-2;
    transition: all 0.2s ease-in;
    


    &:hover {
        transition: all 0.2s ease-in;
        background-color: transparent;
        color: $color-3;
        border: 3px solid $color-1;
    }
}

label.filedoc {
    width: 250px;
    margin: 0;
}